

function mascaraCpfCnpj() {
      return (campo) => {
        if(!campo || campo.length <= 14){
          return '###.###.###-##'
        }else{
          return '##.###.###/####-##'
        }
      }
    }

export { mascaraCpfCnpj }

export default { 
  mascaraCpfCnpj 
}