<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-container class="py-0">
      <v-row>
        <v-col
          v-if="!idEmpresaCliente || idEmpresaCliente < 0"
          cols="12"
        >
          <empresa-cliente-auto-complete
            v-model="empresaClienteModel" 
            :readonly="!!empresaClienteModelId || isEditting"
            :clearable="!isEditting"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nome"
            :rules="[required]"
            :label="$t('ClientesForm.nome')"
            required
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-col
          v-if="objEditar.tipoPessoa === 'J'"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nomeContato"
            :label="$t('ClientesForm.nomeContato')"
            prepend-icon="mdi-factory"
          />
        </v-col>

        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nomeConjuge"
            :label="$t('ClientesForm.nomeConjuge')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>

        <v-col
          cols="5"
          class="hidden-md-and-up text-left"
        >
          <span>{{ $t('ClientesForm.tipoDePessoa') }}</span>
        </v-col>
        <v-col
          cols="7"
          class="hidden-md-and-up text-right"
        >
          <v-radio-group
            v-model="objEditar.tipoPessoa"
            :mandatory="true"
            row
          >
            <v-radio
              :label="$t('ClientesForm.fisica')"
              value="F"
            />
            <v-radio
              :label="$t('ClientesForm.juridica')"
              value="J"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="hidden-sm-and-down"
        >
          <span>{{ $t('ClientesForm.tipoDePessoa') }}</span>
          <v-radio-group
            v-model="objEditar.tipoPessoa"
            :mandatory="true"
            row
          >
            <v-radio
              :label="$t('ClientesForm.fisica')"
              value="F"
            />
            <v-radio
              :label="$t('ClientesForm.juridica')"
              value="J"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :md="objEditar.tipoPessoa === 'F'? 3 : 4"
          :sm="objEditar.tipoPessoa === 'F'? 6 : 4"
        >
          <v-text-field
            v-model="objEditar.cpfOuCnpj"
            v-mask="mascaraCpfCnpj(objEditar.cpfOuCnpj)"
            :label="objEditar.tipoPessoa === 'F'? $t('ClientesForm.cpf') : $t('ClientesForm.cnpj')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>

        <v-col
          v-if="objEditar.tipoPessoa === 'J'"
          cols="12"
          sm="8"
        >
          <v-text-field
            v-model="objEditar.razaoSocial"
            :label="$t('ClientesForm.razaoSocial')"
            prepend-icon="mdi-factory"
          />
        </v-col>

        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.rg"
            :label="$t('ClientesForm.rg')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.orgaoEmissor"
            :label="$t('ClientesForm.orgaoEmissor')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          md="3"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.dataRG"
            :label="$t('ClientesForm.dataRG')"
            prepend-icon="mdi-account-card-details"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nomePai"
            :label="$t('ClientesForm.nomePai')"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
        <v-col
          v-if="objEditar.tipoPessoa === 'F'"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="objEditar.nomeMae"
            :label="$t('ClientesForm.nomeMae')"
            prepend-icon="mdi-account-edit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.whatsapp"
            :label="$t('ClientesForm.whatsapp')"
            :messages="[`${$t('padrao_exemplo')} 5547997005258`]"
          >
            <v-tooltip
              slot="prepend" 
              bottom
              style="margin: 0px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="v-btn--simple"
                  icon
                  color="green"
                  :href="`http://wa.me/${objEditar.whatsapp.trim()}`"
                  target="blank"
                  style="margin: 0px"
                  v-on="on"
                >
                  <v-icon 
                    style="margin: 0px"
                  >
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('ClientesForm.abrir_whatsapp') }}</span>
            </v-tooltip>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-menu
            ref="menuDataNascimento"
            v-model="menuDataNascimento"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataNascimentoFormatted"
                :label="$t('ClientesForm.dataNascimento')"
                persistent-hint
                readonly
                :rules="[required]"
                required
                prepend-icon="mdi-calendar-text"
                @blur="objEditar.dataNascimento = parseDate(dataNascimentoFormatted)"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="objEditar.dataNascimento"
              no-title
              dark
              @input="menuDataNascimento = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <endereco-form :obj-editar="objEditar" />
      <v-row>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.telefone1"
            :label="$t('ClientesForm.telefone1')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.tipoTelefone1"
            :label="$t('ClientesForm.tipoTelefone1')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.telefone2"
            :label="$t('ClientesForm.telefone2')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.tipoTelefone2"
            :label="$t('ClientesForm.tipoTelefone2')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.telefone3"
            :label="$t('ClientesForm.telefone3')"
            prepend-icon="mdi-phone-classic"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            v-model="objEditar.tipoTelefone3"
            :label="$t('ClientesForm.tipoTelefone3')"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email1"
            :label="$t('ClientesForm.email1')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email2"
            :label="$t('ClientesForm.email2')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="objEditar.email3"
            :label="$t('ClientesForm.email3')"
            prepend-icon="mdi-email-outline"
          />
        </v-col>
        <v-alert
          v-model="alertValidacao"
          dismissible
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </v-row>
    </v-container>
  </crud-form>
</template>

<script>

  import Servico from '@/services/ClientesService'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import DateUtils from '../utils/DateUtils'
  import { mapState } from 'vuex'
  import EmpresaClientesService from '@/services/EmpresaClientesService'
  import {mascaraCpfCnpj} from '../utils/ComputedMasks'

  export default {
    components: {
      EmpresaClienteAutoComplete
    },
    data: () => ({
      empresaClienteModel: null,
      menuDataNascimento: false,
      empresaClienteModelId: null,
      objPadrao: {
        nome: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        apelido: '',
        rg: '',
        razaoSocial: '',
        orgaoEmissor: '',
        dataRG: null,
        dataNascimento: null,
        nomeConjuge: '',
        nomeContato: '',
        nomePai: '',
        nomeMae: '',
        telefone1: null,
        tipoTelefone1: '',
        telefone2: null,
        tipoTelefone2: '',
        telefone3: null,
        tipoTelefone3: '',
        whatsapp: '',
        email1: '',
        email2: '',
        email3: '',
        id: 0,
        idEmpresaCliente: null
      },
      objEditar: {
        nome: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        apelido: '',
        rg: '',
        razaoSocial: '',
        orgaoEmissor: '',
        dataRG: null,
        dataNascimento: null,
        nomeConjuge: '',
        nomeContato: '',
        nomePai: '',
        nomeMae: '',
        telefone1: null,
        tipoTelefone1: '',
        telefone2: null,
        tipoTelefone2: '',
        telefone3: null,
        tipoTelefone3: '',
        whatsapp: '',
        email1: '',
        email2: '',
        email3: '',
        id: 0,
        idEmpresaCliente: null
      },
      required: value => !!value || 'Campo Obrigatório',
      isEditting: false,
      error: null,
      validForm: false,
      alertValidacao: false
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      ...{mascaraCpfCnpj},
      dataNascimentoFormatted: function () {
        return DateUtils.formatDate(this.objEditar.dataNascimento)
      },
      mensagemPerguntaRemocao () {
        return this.$i18n.t('padrao_deseja_remover_registro')
      },
      tituloPagina () {
        return this.$i18n.t('ClientesForm.tituloPagina')
      }

    },
    watch: {
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
          this.empresaClienteModelId = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
          this.empresaClienteModelId = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao

        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          EmpresaClientesService.findById(this.idEmpresaCliente).then((res) => {
            this.objEditar.EmpresaCliente = res.data
            this.empresaClienteModel = res.data
          })
        }
      }
    },
    methods: {
      traduzir (value) {
        try {
          return this.$t(value)
        } catch (error) {
          return value
        }
      },

      parseDate (date) {
        return DateUtils.parseDate(date)
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_removido_sucesso')
              })
              this.buscar()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover o registrro:\n ->' + error
          })
        }
      },
      saveContinueEvent () {
        if (this.idEmpresaCliente) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar, true)
      },
      closeDialog () {
        this.buscar()
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                })
                this.buscar()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      buscar () {
        this.$router.push('/clientes')
      },
      inserir (registro, continuar) {
        try {
          Servico.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (continuar) {
                this.editar(response.data.id)
              } else {
                this.buscar()
              }
            }
          })
            .catch(error => {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Ocorreu um erro ao inserir um cliente:\n ->' + error
              })
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um cliente:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then(registro => {
            this.objEditar = registro.data
            this.isEditting = true
            this.empresaClienteModel = this.objEditar.EmpresaCliente
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
